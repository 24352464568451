import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import OtpInput from 'react-otp-input'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useUserAuth } from '../context/UserAuthContext'
import {
  CButton,
  CCol,
  CContainer,
  CRow,
  CForm,
  CFormFloating,
  CFormFeedback,
  CImage,
} from '@coreui/react-pro'
import Toast from '../common/toast/Toast'
import { useDispatch } from 'react-redux'
import { updateUserData, multipleAPICalls } from '../services/redux-store/appSlice'
const PhoneSignUp = () => {
  const [error, setError] = useState('')
  const [number, setNumber] = useState('')
  const [flag, setFlag] = useState(false)
  const [otp, setOtp] = useState('')
  const [result, setResult] = useState('')
  const [toast, setToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [toastColor, setToastColor] = useState('')
  const { setUpRecaptha } = useUserAuth()
  const history = useHistory()
  const dispatch = useDispatch()
  const getOtp = async (e) => {
    e.preventDefault()
    console.log(number)
    setError('')
    if (number === '' || number === undefined) return setError('Please enter a valid phone number!')
    try {
      const response = await setUpRecaptha(number)
      setResult(response)
      setFlag(true)
      setError('')
    } catch (err) {
      console.log(err)
      setError('Please enter a valid Phone number!')
    }
  }

  const verifyOtp = async (e) => {
    e.preventDefault()
    setError('')
    if (otp === '' || otp === null) return setError('Please enter OTP!')
    try {
      await result.confirm(otp)
      const response = []
      dispatch(multipleAPICalls(response))
        .then((result) => {
          var userType = result?.user?.users_data[0]?.user_type
          var checkUserType = userType.includes(userType.find((data) => data === 'internal'))
          if (checkUserType === true) {
            dispatch(updateUserData(result))
            // var objAllowData = {
            //   category_list: true,
            //   category_add: true,
            //   category_edit: true,
            //   category_active: true,
            //   category_view: true,
            //   brand_list: true,
            //   brand_add: true,
            //   brand_edit: true,
            //   brand_active: true,
            //   brand_view: true,
            //   role_list: true,
            //   role_add: true,
            //   role_edit: true,
            //   role_active: true,
            //   role_view: true,
            //   subcategory_list: true,
            //   subcategory_add: true,
            //   subcategory_edit: true,
            //   subcategory_active: true,
            //   subcategory_view: true,
            //   store_list: true,
            //   store_add: true,
            //   store_edit: true,
            //   store_active: true,
            //   store_view: true,
            //   product_list: true,
            //   product_add: true,
            //   product_edit: true,
            //   product_active: true,
            //   product_view: true,
            //   producttype_list: true,
            //   producttype_add: true,
            //   producttype_edit: true,
            //   producttype_active: true,
            //   producttype_view: true,
            //   makers_list: true,
            //   makers_add: true,
            //   makers_edit: true,
            //   makers_active: true,
            //   makers_view: true,
            //   permission_list: true,
            //   permission_add: true,
            //   permission_edit: true,
            //   permission_active: true,
            //   permission_view: true,
            //   package_list: true,
            //   package_add: true,
            //   package_edit: true,
            //   package_active: true,
            //   package_view: true,
            //   bundle_list: true,
            //   bundle_add: true,
            //   bundle_edit: true,
            //   bundle_active: true,
            //   bundle_view: true,
            //   serviceitem_list: true,
            //   serviceitem_add: true,
            //   serviceitem_edit: true,
            //   serviceitem_active: true,
            //   serviceitem_view: true,
            //   facility_list: true,
            //   facility_add: true,
            //   facility_edit: true,
            //   facility_active: true,
            //   facility_view: true,
            //   employee_list: true,
            //   employee_add: true,
            //   employee_edit: true,
            //   employee_active: true,
            //   employee_view: true,
            //   location_add: true,
            // }
            // const strObj = JSON.stringify(objAllowData)
            localStorage.setItem(
              'menu_permissions',
              JSON.stringify(result?.user?.menu_permissions1),
            )
            localStorage.setItem('user_permissions', JSON.stringify(result?.user?.permissions))
            // localStorage.setItem('strObj', strObj)
            localStorage.setItem('token', true)
            history.push(`/dashboard`)
          } else {
            setToast(true)
            setToastColor('red')
            setToastMessage('Unauthorized User')
            setTimeout(() => {
              setToast(false)
              setToastMessage('')
              localStorage.clear()
              document.location.href = '/'
            }, 3000)
          }
        })
        .catch((err) => {
          setToast(true)
          setToastColor('red')
          setToastMessage('Unauthorized User')
          setTimeout(() => {
            setToast(false)
            setToastMessage('')
            localStorage.clear()
            document.location.href = '/'
          }, 3000)
        })
    } catch (err) {
      setToast(true)
      setToastColor('red')
      setToastMessage(
        <>
          {!!err?.response?.data?.message === true ? err?.response?.data?.message : err.message},
        </>,
      )
      setTimeout(() => {
        setToast(false)
        setToastMessage('')
        localStorage.clear()
        document.location.href = '/'
      }, 3000)
    }
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <Toast toast={toast} color={toastColor} toastMessage={toastMessage} />
        <CRow className="justify-content-center">
          <CCol md={6} className="bg-white p-4">
            {flag === false ? (
              <CCol className="bg-white">
                <center>
                  <CImage src="./houeze-1.png" height={75} width={150} className="mb-2 mt-2" />

                  <CForm onSubmit={getOtp}>
                    <CCol md={8} lg={8} xl={8}>
                      <CFormFloating className="mb-3">
                        <PhoneInput
                          defaultCountry="IN"
                          value={number}
                          onChange={setNumber}
                          placeholder="Enter Phone Number"
                        />
                        {!!error === true ? (
                          <CFormFeedback>
                            <div style={{ color: 'red' }}>{error}</div>
                          </CFormFeedback>
                        ) : (
                          ''
                        )}
                      </CFormFloating>
                      <div className="mb-1" id="recaptcha-container"></div>
                    </CCol>
                    <CRow>
                      <CCol className="button-right pt-2 mb-3">
                        <CButton size="sm" color="secondary" onClick={() => setNumber('')}>
                          Cancel
                        </CButton>
                        <CButton size="sm" className="m-2" color="primary" type="submit">
                          Send Otp
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </center>
              </CCol>
            ) : (
              <>
                <div>
                  <p className="fs-5 fw-bold">Verification Code</p>
                  <small>We have sent the code verification to Your Mobile Number</small>
                </div>
                <CForm onSubmit={verifyOtp}>
                  <center>
                    <CCol
                      style={{
                        fontFamily: 'sansSerif',
                        textAlign: 'center',
                        backgroundColor: '#f3f3f3',
                        padding: '20px',
                        margin: 'auto',
                      }}
                      md={8}
                      lg={8}
                      xl={8}
                      className="mx-auto"
                    >
                      <OtpInput
                        value={otp}
                        onChange={(otp) => setOtp(otp)}
                        numInputs={6}
                        separator={<span style={{ width: '8px' }}></span>}
                        isInputNum={true}
                        className="mx-auto"
                        shouldAutoFocus={true}
                        inputStyle={{
                          border: '1px solid transparent',
                          borderRadius: '8px',
                          width: '40px',
                          height: '40px',
                          fontSize: '12px',
                          color: '#000000',
                          fontWeight: '400',
                        }}
                        focusStyle={{
                          border: '1px solid #CFD3DB',
                          outline: 'none',
                        }}
                      />
                      {!!error === true ? (
                        <CFormFeedback>
                          <div style={{ color: 'red' }}>{error}</div>
                        </CFormFeedback>
                      ) : (
                        ''
                      )}
                    </CCol>

                    <CButton size="sm" className="button-right mt-2" color="primary" type="submit">
                      Submit
                    </CButton>
                  </center>
                </CForm>
              </>
            )}
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default PhoneSignUp
