import React from 'react'
import { CToast, CToastBody, CToastClose } from '@coreui/react-pro'

const Toast = (props) => {
  return (
    <CToast
      style={{
        backgroundColor: props.color,
        color: 'white',
        position: 'absolute',
        top: '100px',
        right: 0,
        zIndex: 99999,
      }}
      autohide={false}
      visible={props.toast}
    >
      <div className="d-flex">
        <CToastBody>{props.toastMessage}</CToastBody>
        <CToastClose className="me-2 m-auto" />
      </div>
    </CToast>
  )
}
export default Toast
